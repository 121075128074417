// CORE
import {combineReducers} from '@reduxjs/toolkit';

// REDUCERS
import admin               from "./admin";
import team                from "./team";
import invoice             from "./invoice";
import log                 from "./log";
import vm                  from "./virtualMachines";
import cost                from "./cost";
import payed               from "./payed";
import payment             from "./payment"
import quota               from "./quota"
import coupons             from "./coupons";
import promos              from "./promos";
import resources           from "./resources";
import serverModeStore     from "./serverModes";
import userStore           from "./user";
import activityStore       from "./activity";
import timeActivity        from "./timeActivity";
import cluster             from "./cluster";
import database            from "./database";
import app                 from "./app";
import billingSetting      from "./billingSetting";
import stripeCustomerStore from './stripeCustomers';
import cloudProviderStore  from './cloudProvider';
import providerStore       from './provider';
import publicConfigStore   from './publicConfig';
import imageStore          from './images';

export const rootReducer = combineReducers({
  admin           : admin.reducer,
  team            : team.reducer,
  invoice         : invoice.reducer,
  log             : log.reducer,
  vm              : vm.reducer,
  cost            : cost.reducer,
  payment         : payment.reducer,
  quota           : quota.reducer,
  coupons         : coupons.reducer,
  promos          : promos.reducer,
  resources       : resources.reducer,
  serverMode      : serverModeStore.reducer,
  user            : userStore.reducer,
  activity        : activityStore.reducer,
  timeActivity    : timeActivity.reducer,
  cluster         : cluster.reducer,
  database        : database.reducer,
  app             : app.reducer,
  billingSetting  : billingSetting.reducer,
  stripeCustomers : stripeCustomerStore.reducer,
  payed           : payed.reducer,
  cloudProviders  : cloudProviderStore.reducer,
  providers       : providerStore.reducer,
  publicConfig    : publicConfigStore.reducer,
  images          : imageStore.reducer,
});
