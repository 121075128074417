// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import {defaultPagination} from "../../constants/pagination";


const quotaSlice = createSlice({
    name: 'quota',
    initialState: {
        providerQuotas: [],
        defaultQuotas: {
            items: [],
            info: defaultPagination,
        },
        teamQuotas: {
            items: [],
            info: defaultPagination,
        }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(asyncActions.getProviderQuotas.fulfilled, (state, {payload}) => {
                state.providerQuotas = payload.data;
            })
            .addCase(asyncActions.getDefaultQuotas.fulfilled, (state, {payload}) => {
                const {docs, ...pagination} = payload.data;
                state.defaultQuotas.items = docs;
                state.defaultQuotas.info = {...pagination}
            })
            .addCase(asyncActions.updateDefaultQuota.fulfilled, (state, {payload}) => {
                const quota = payload.data;

                state.defaultQuotas.items = state.defaultQuotas.items.map(item => item._id === quota._id ? quota : item);
            })
            .addCase(asyncActions.getTeamQuotas.fulfilled, (state, {payload}) => {
                const {docs, ...pagination} = payload.data;
                state.teamQuotas.items = docs;
                state.teamQuotas.info = {...pagination}
            })
    },
});

export const selectProviderQuoteItems = state => state.quota.providerQuotas;
export const selectDefaultQuoteItems = state => state.quota.defaultQuotas.items;
export const selectDefaultQuoteInfo = state => state.quota.defaultQuotas.info;
export const selectTeamQuoteItems = state => state.quota.teamQuotas.items;
export const selectTeamQuoteInfo = state => state.quota.teamQuotas.info;


export default ({
    ...quotaSlice,
    asyncActions,
});
