// CORE
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import {useDispatch} from "react-redux";
// MUI
import {
    Box, Breadcrumbs, Button,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from "@mui/material";
// STYLES
import './dashBoard.scss';
// STORE
import adminStore from '../../../store/admin';
// COMPONENTS
import { menuList } from "./constants";
import publicUrl from "../../../helpers/publicUrl";
import PublicLink from "../../shared/Link";

const publicItem = ({ link, children, ...menuItem }) => ({
  ...menuItem,
  link: publicUrl(link),
  children: (children && Array.isArray(children))
      ? children.map(child => publicItem(child))
      : children || null
});

const currentMenuList = menuList.map(publicItem);

const DashboardLayout = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentPath = Object.entries(params).reduce((prev, [key, value]) => {
        return prev.replace(value, `:${key}`)
    }, location.pathname);

    const currentRoute = currentMenuList.find(item => item.link === currentPath || item.children.find(child => child.link === currentPath))
    const currentChildren = currentRoute.children.find(item => item.link === currentPath);
    const breadcrumbs = currentRoute.children.filter(child => child?.step <= currentChildren?.step);

    const handleLogout = () => {
        localStorage.removeItem('api-key');
        dispatch(adminStore.actions.clearCurrentAdmin());
        navigate(publicUrl('/login'));
    }

    return (
        <Box display="flex" width="100%" height="100%">
            <Box
                style={{
                    width: '250px',
                    flexDirection: 'column',
                    display: 'flex',
                    height: '100vh',
                    position: 'fixed',
                    borderRight: '1px solid #dfdfdf',
                    backgroundColor: 'white',

                }}
            >
                <Toolbar/>
                <Divider/>
                <List className="menu-list">
                    {menuList.map((item) => {
                        const activeClass = item.link === currentRoute.link ? 'active' : ''

                        return (
                            <PublicLink
                                key={item.label}
                                className={`menu-list__link ${activeClass}`}
                                to={item.link}>
                                <ListItem key={item.label} className="menu-list__item">
                                    <ListItemIcon className="menu-list__icon">
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText className="menu-list__label">{item.label}</ListItemText>
                                </ListItem>
                            </PublicLink>
                        )
                    })}
                    <Divider/>
                    <Box padding="20px" display="flex" justifyContent="center">
                        <Button onClick={handleLogout} variant="contained">Log out</Button>
                    </Box>
                </List>
            </Box>
            <Box paddingLeft="250px" width="100%" display="flex" flexDirection="column">
                <Breadcrumbs className="dashboard-header" aria-label="breadcrumb">
                    <PublicLink
                        className={`dashboard-header__link ${!breadcrumbs.length ? 'active' : ''}`}
                        to={currentRoute.link}>
                        {currentRoute.label}
                    </PublicLink>
                    {breadcrumbs.map(item => {
                        const breadcrumbLink = Object.entries(params).reduce((prev, [key, value]) => {
                            if (!item.params.includes(key)) return prev;

                            return prev.replace(`:${key}`, value)
                        }, item.link);

                        return (
                            <PublicLink key={item.link} className='dashboard-header__current-link' to={breadcrumbLink}>
                                {item.name}
                            </PublicLink>
                        )
                    })}
                </Breadcrumbs>
                <Divider/>
                <Outlet/>
            </Box>
        </Box>
    )
}

export default DashboardLayout;
