// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import logService from '../../services/log';


export default ({
    getLogs: createAsyncThunk('get/logs', async (params) => logService.getLogs(params)),
    getLogDetails: createAsyncThunk('get/logDetails', async (params) => logService.getLogDetails(params)),
    deleteLogById: createAsyncThunk('get/deleteLogById', async (params) => logService.deleteLogById(params)),
    deleteLogsByIds: createAsyncThunk('get/deleteLogsByIds', async (params) => logService.deleteLogsByIds(params)),
});
