// CORE
import React from "react";

// MUI
import GroupsIcon         from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CloudDoneIcon      from '@mui/icons-material/CloudDone';
import StorageIcon        from '@mui/icons-material/Storage';
import DiscountIcon       from '@mui/icons-material/Discount';
import LocalOfferIcon     from '@mui/icons-material/LocalOffer';
import GroupIcon          from '@mui/icons-material/Group';
import PersonRemoveIcon   from '@mui/icons-material/PersonRemove';
import ComputerIcon       from '@mui/icons-material/Computer';
import AccountBalance     from "@mui/icons-material/AccountBalance";
import CloudIcon          from "@mui/icons-material/Cloud";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';

export const menuList = [
  {
    label: 'Deletion Requests',
    link: '/deletion-requests',
    icon: <PersonRemoveIcon/>,
    children: []
  },
  {
    label: 'Users',
    link: '/users',
    icon: <GroupIcon/>,
    children: [{
      step: 1,
      name: 'User Details',
      link: '/users/:id',
      params: ['id']
    }]
  },
  {
    label: 'Teams',
    link: '/teams',
    icon: <GroupsIcon/>,
    children: [{
      step: 1,
      name: 'Team Details',
      link: '/teams/:id',
      params: ['id']
    }]
  },
  {
    label: 'Resources',
    link: '/resources',
    icon: <ComputerIcon/>,
    children: [{
      step: 1,
      name: 'Resource Details',
      link: '/resources/:resourceId',
      params: ['resourceType', 'resourceId']
    }]
  },
  {
    label: "Billing Settings",
    link: '/billing-settings',
    icon: <AccountBalance />,
    children: [{
      step   : 1,
      name   : "Billing Setting Details",
      link   : "/billing-settings/:billingSettingId",
      params : ["billingSettingId"],
    }]
  },
  {
    label: 'Server Modes',
    link: '/server-modes',
    icon: <StorageIcon/>,
    children: []
  },
  {
    label: 'Quotas',
    link: '/quotas',
    icon: <CloudDoneIcon/>,
    children: []
  },
  {
    label: 'Invoices',
    link: '/invoices',
    icon: <MonetizationOnIcon/>,
        children : [{
            step:1,
            name : 'Invoice Details',
            link : '/invoices/:id',
            params : ['id']
        }]
    },
  {
    label    : 'Providers',
    link     : '/providers',
    icon     : <CloudIcon />,
    children : [],
  },
  {
    label : 'Coupons',
    link  : '/coupons',
    icon  : <LocalOfferIcon/>,
    children : [{
      step   : 1,
      name   : 'Coupon Details',
      link   : '/coupons/:couponId',
      params : ['couponId']
    }],
  },
  {
    label : 'Promo codes',
    link  : '/promos',
    icon  : <DiscountIcon/>,
    children : [{
      step   : 1,
      name   : 'Promo Code Details',
      link   : '/promos/:promoId',
      params : ['promoId']
    }],
  },
  {
    label: "Images",
    link: "/images",
    icon: <PlaylistAddCircleIcon />,
    children: [],
  }
];
