// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import providerService from '../../services/provider';

const cloudProviderAsyncActions = Object.freeze({
  getProviders: createAsyncThunk('get/getProviders', providerService.getProviders),
  activate    : createAsyncThunk('post/activateProvider', providerService.activate),
  deactivate  : createAsyncThunk('delete/deactivateProvider', providerService.deactivate),
});

export default cloudProviderAsyncActions;
