// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from "../../constants/pagination";


const logSlice = createSlice({
    name: 'log',
    initialState: {
        items: [],
        info: defaultPagination,
        current: null,
        isLoading: false
    },
    reducers: {
        clearLogs(state) {
            state.items = [];
            state.info = defaultPagination;

            return state;
        },
        clearLogDetails(state) {
            state.current = null;

            return state;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(asyncActions.getLogs.fulfilled, (state, { payload }) => {
                const { docs, ...pagination } = payload.data;
                state.items = docs;
                state.info = { ...pagination }

                state.isLoading = false;
            })
            .addCase(asyncActions.getLogs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getLogs.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(asyncActions.getLogDetails.fulfilled, (state, { payload }) => {
                state.current = payload.data;

                state.isLoading = false;
            })
            .addCase(asyncActions.getLogDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getLogDetails.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export default ({
    ...logSlice,
    asyncActions,
});
