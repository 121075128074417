import axios from "axios";
import trap from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop from "../../helpers/fp/object/prop";
import apiClient from "../../libs/apiClient";

const API_URL                   = process.env.REACT_APP_BASE_API_URL;
const CLOUD_PROVIDER_PATH       = "cloud-providers";
const CLOUD_PROVIDER_HOST       = pathsJoin([API_URL, CLOUD_PROVIDER_PATH]);

const getCloudProviders = trap(() => axios.get(CLOUD_PROVIDER_HOST).then(prop("data")));
const activate          = trap((cloudProviderId) => apiClient.post(pathsJoin([CLOUD_PROVIDER_PATH, cloudProviderId])));
const deactivate        = trap((cloudProviderId) => apiClient.delete(pathsJoin([CLOUD_PROVIDER_PATH, cloudProviderId])));

const cloudProviderService = Object.freeze({
  getCloudProviders,
  activate,
  deactivate,
});

export default cloudProviderService;
