// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// CONSTANTS
import emptyArray from '../../helpers/fp/array/empty';
import updateManyPayload from '../../helpers/pp/array/updateManyPayload';
import findIndexById from '../../helpers/fp/array/findIndexById';

const setStatus = (isDisabled) => (state, { meta }) => {
  const providerId = meta.arg;
  const providerIndex = findIndexById(providerId)(state);
  return providerIndex < 0 || isDisabled === state[providerIndex].isDisabled
    ? state
    : state.slice(0, providerIndex).concat({
      ...state[providerIndex],
      isDisabled,
    }, state.slice(providerIndex + 1))
};

const actiavateById  = setStatus(false);
const deactivateById = setStatus(true);

const providerSlice = createSlice({
  name          : 'provider',
  initialState  : emptyArray(),
  reducers      : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getProviders.fulfilled, updateManyPayload)
      .addCase(asyncActions.activate.pending,     actiavateById)
      .addCase(asyncActions.activate.fulfilled,   actiavateById)
      .addCase(asyncActions.deactivate.pending,   deactivateById)
      .addCase(asyncActions.deactivate.fulfilled, deactivateById)
  },
});

const providerStore = Object.freeze({
  ...providerSlice,
  asyncActions,
});

export default providerStore;
