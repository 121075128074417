// CORE
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import publicUrl from "../../../helpers/fp/publicUrl";


const ProtectedRoute = ({ children }) => {
    const admin = useSelector((state) => state.admin.current);

    if (!admin) return <Navigate replace to={publicUrl("/login")}/>

    return children;
}

export default ProtectedRoute;
