import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

// COMPONENTS
import NoMatch from "./pages/NoMatch/NoMatch";
import SuspenseRoute from "./components/shared/SuspenseRoute/SuspenseRoute";
import DashboardLayout from "./components/layouts/DashboardLayout/DashboardLayout";
import ProtectedRoute from "./components/shared/ProtectedRoute/ProtectedRoute";
import PublicRoute from "./components/shared/PublicRoute/PublicRoute";
import publicUrl from "./helpers/publicUrl";

// LAZY PAGES

const Login = React.lazy(() => import("./pages/Login/LoginContainer"));
// const Accounts = React.lazy(() => import("./pages/Accounts/Accounts"));
const Teams           = React.lazy(() => import("./pages/Teams"));
const TeamDetails     = React.lazy(() => import("./pages/TeamDetails/TeamDetailsContainer"));
const Invoices        = React.lazy(() => import("./pages/Invoices/InvoicesContainer"));
const InvoiceItem     = React.lazy(() => import("./pages/InvoiceItem/InvoiceItemContainer"));
const Quotes          = React.lazy(() => import("./pages/Quotes"));
const ServerModes     = React.lazy(() => import("./pages/ServerModes"));
const CouponsPage     = React.lazy(() => import("./pages/Coupons"));
const CouponPage      = React.lazy(() => import("./pages/Coupons/ID"));
const PromosPage      = React.lazy(() => import("./pages/Promos"));
const PromoPage       = React.lazy(() => import("./pages/Promos/ID"));
const UsersPage       = React.lazy(() => import("./pages/Users/Users"));
const UserDetailsPage = React.lazy(() => import("./pages/UserDetails/UserDetails"));
const PurgedAccounts  = React.lazy(() => import("./pages/AccountDeletionRequests"));
const Resources       = React.lazy(() => import("./pages/Resources"));
const ResourceDetails = React.lazy(() => import("./pages/ResourceDetails"));
const BillingSettings = React.lazy(() => import("./pages/BillingSettings"));
const BillingSetting  = React.lazy(() => import("./pages/BillingSettings/ID"));
const ProvidersPage   = React.lazy(() => import("./pages/Providers"));
const ImagesPage      = React.lazy(() => import("./pages/Images"));

const AppRouter = () => {
  return (
    <Routes>
      <Route path={publicUrl("/")} element={<Navigate replace to={publicUrl("/teams")}/>}/>
      <Route path="/" element={<ProtectedRoute><DashboardLayout/></ProtectedRoute>}>
        <Route path={publicUrl("/teams")} element={<SuspenseRoute><Teams/></SuspenseRoute>}/>
        <Route path={publicUrl("/teams/:id")} element={<SuspenseRoute><TeamDetails/></SuspenseRoute>}/>
        <Route path={publicUrl("/invoices")} element={<SuspenseRoute><Invoices/></SuspenseRoute>}/>
        <Route path={publicUrl("/invoices/:id")} element={<SuspenseRoute><InvoiceItem/></SuspenseRoute>}/>
        <Route path={publicUrl("/deletion-requests")} element={<SuspenseRoute><PurgedAccounts/></SuspenseRoute>}/>
        <Route path={publicUrl("/quotas")} element={<SuspenseRoute><Quotes/></SuspenseRoute>}/>
        <Route path={publicUrl("/server-modes")} element={<SuspenseRoute><ServerModes/></SuspenseRoute>}/>
        <Route path={publicUrl("/users")} element={<SuspenseRoute><UsersPage/></SuspenseRoute>}/>
        <Route path={publicUrl("/users/:id")} element={<SuspenseRoute><UserDetailsPage/></SuspenseRoute>}/>
        <Route path={publicUrl("/resources")} element={<SuspenseRoute><Resources/></SuspenseRoute>}/>
        <Route path={publicUrl("/resources/:resourceId")} element={<SuspenseRoute><ResourceDetails/></SuspenseRoute>}/>
        <Route
          path={publicUrl("/coupons")}
          element={
            <SuspenseRoute>
              <CouponsPage/>
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/coupons/:couponId")}
          element={
            <SuspenseRoute>
              <CouponPage/>
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/promos")}
          element={
            <SuspenseRoute>
              <PromosPage/>
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/promos/:promoId")}
          element={
            <SuspenseRoute>
              <PromoPage/>
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/billing-settings")}
          element={
            <SuspenseRoute>
              <BillingSettings/>
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/billing-settings/:billingSettingId")}
          element={
            <SuspenseRoute>
              <BillingSetting />
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/providers")}
          element={
            <SuspenseRoute>
              <ProvidersPage/>
            </SuspenseRoute>
          }
        />
        <Route
          path={publicUrl("/images")}
          element={
            <SuspenseRoute>
              <ImagesPage/>
            </SuspenseRoute>
          }
        />
      </Route>
      <Route index element={<Navigate replace to={publicUrl("/login")}/>}/>
      <Route path={publicUrl("/login")} element={<SuspenseRoute><PublicRoute><Login/></PublicRoute></SuspenseRoute>}/>
      <Route path="*" element={<NoMatch/>}/>
    </Routes>
  );
};

export default AppRouter;
