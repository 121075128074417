import axios from "axios";

import apiClient from "../../libs/apiClient";

import trap      from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop      from "../../helpers/fp/object/prop";

const API_URL       = process.env.REACT_APP_BASE_API_URL;
const IMAGES_PATH  = "images";
const IMAGES_HOST  = pathsJoin([API_URL, IMAGES_PATH]);

const getImages = trap(() => axios.get(IMAGES_HOST).then(prop("data")));
const activate     = trap((providerId) => apiClient.post(pathsJoin([IMAGES_PATH, providerId])));
const deactivate   = trap((providerId) => apiClient.delete(pathsJoin([IMAGES_PATH, providerId])));


const imageService = Object.freeze({
  getImages,
  activate,
  deactivate,
});

export default imageService;
