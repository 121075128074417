// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import { objectToURL } from "../../helpers/query";


const getLogs = (params) => apiClient.get(`/logs?${objectToURL(params)}`);

const getLogDetails = ({id}) => apiClient.get(`/logs/${id}`);

const deleteLogById = ({id}) => apiClient.delete(`/logs/${id}`);

const deleteLogsByIds = ({ids}) => apiClient.delete(`/logs?ids=${ids}`);

export default {
    getLogs,
    getLogDetails,
    deleteLogById,
    deleteLogsByIds
}
