import axios from "axios";


const apiClient = axios.create({
    baseURL : process.env.REACT_APP_API_URL, headers : {
        "Content-Type" : "application/json",
    }
});

apiClient.interceptors.request.use((config) => {
    const apiKey = localStorage.getItem('api-key');

    if (apiKey) config.headers['api-key'] = apiKey;

    return config;
});

apiClient.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === 401) {
        localStorage.removeItem('api-key');
    }

    return Promise.reject(error);
});

export default apiClient;
