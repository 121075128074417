// CORE
import React from "react";
import { BrowserRouter } from "react-router-dom";
import {ToastContainer} from "react-toastify";
// MUI
import { CssBaseline, ThemeProvider } from "@mui/material";
// STYLES
import "./styles/main.scss";
import 'react-toastify/dist/ReactToastify.css';
// ASSETS
import { muiTheme } from "./assets/theme";
// COMPONENTS
import AppRouter from "./AppRouter";
import ProvideAuth from "./hoc/ProvideAuth";

const App = () => {
    return (
        <ThemeProvider theme={muiTheme}>
            <CssBaseline/>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={3}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="colored"
                style={{ fontSize: '16px' }}
            />
            <BrowserRouter>
                <ProvideAuth>
                    <AppRouter/>
                </ProvideAuth>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
