// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const adminSlice = createSlice({
    name : 'admin',
    initialState : {
        current : null,
        isLoading : false
    },
    reducers : {
        clearCurrentAdmin(state) {
            state.current = null;

            return state;
        }
    },
    extraReducers : builder => {
        builder
            .addCase(asyncActions.getProfile.fulfilled, (state, { payload }) => {
                state.current = payload.data;
                state.isLoading = false;
            })
            .addCase(asyncActions.getProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getProfile.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export default ({
    ...adminSlice,
    asyncActions,
});
