// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from "../../constants/pagination";


const vmSlice = createSlice({
    name: 'vms',
    initialState: {
      items     : [],
      info      : defaultPagination,
      current   : null,
      isLoading : false,
      isError   : false,
    },
    reducers: {
        clearVMs(state) {
            state.items = [];
            state.info = defaultPagination;

            return state;
        },
        clearVM(state) {
            state.current = null;
            state.isError = false;

            return state;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(asyncActions.getVMs.fulfilled, (state, {payload}) => {
                const {docs, ...pagination} = payload.data;
                state.items = docs;
                state.info = {...pagination}

                state.isLoading = false;
            })
            .addCase(asyncActions.getVMs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getVMs.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(asyncActions.getVirtualMachineById.fulfilled, (state, {payload}) => {
                state.current = payload.data;

                state.isLoading = false;
            })
            .addCase(asyncActions.getVirtualMachineById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getVirtualMachineById.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    },
});

const virtualMachineStore = Object.freeze({
  ...vmSlice,
  asyncActions,
});

export default virtualMachineStore;
