// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import quotaService from '../../services/qouta';

export default ({
    getProviderQuotas: createAsyncThunk('get/providerQuotas', quotaService.getProviderQuotas),
    getDefaultQuotas: createAsyncThunk('get/defaultQuotas', quotaService.getDefaultQuotas),
    updateDefaultQuota: createAsyncThunk('put/defaultQuota', quotaService.updateDefaultQuota),
    updateTeamQuota: createAsyncThunk('put/teamQuota', quotaService.updateTeamQuota),
    getTeamQuotas: createAsyncThunk('get/teamQuotas', quotaService.getTeamQuotas),
});
