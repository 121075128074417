import axios from "axios";
import trap from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop from "../../helpers/fp/object/prop";
import apiClient from "../../libs/apiClient";

const API_URL             = process.env.REACT_APP_BASE_API_URL;
const PROVIDER_PATH = "providers";
const PROVIDER_HOST = pathsJoin([API_URL, PROVIDER_PATH]);

const getProviders = trap(() => axios.get(PROVIDER_HOST).then(prop("data")));
const activate     = trap((providerId) => apiClient.post(pathsJoin([PROVIDER_PATH, providerId])));
const deactivate   = trap((providerId) => apiClient.delete(pathsJoin([PROVIDER_PATH, providerId])));

const providerService = Object.freeze({
  getProviders,
  activate,
  deactivate,
});

export default providerService;
