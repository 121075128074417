// CORE
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import publicUrl from "../../../helpers/publicUrl";


const PublicRoute = ({ children }) => {
  const admin = useSelector((state) => state.admin.current);
  if (admin) return <Navigate replace to={publicUrl("/teams")}/>
  return children;
}

export default PublicRoute;
