// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";

const getProviderQuotas = () => apiClient.get(`/provider-quotas`);
const getDefaultQuotas = (params) => apiClient.get(`/default-quotas?${objectToURL(params)}`);
const getTeamQuotas = (params) => apiClient.get(`/team-quotas?${objectToURL(params)}`);
const updateDefaultQuota = ({id, data}) => apiClient.put(`/default-quotas/${id}`, data);
const updateTeamQuota = ({id, data}) => apiClient.put(`/team-quotas/${id}`, data);

export default {
    getProviderQuotas,
    getDefaultQuotas,
    updateDefaultQuota,
    getTeamQuotas,
    updateTeamQuota
}
